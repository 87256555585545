<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img class="mb-2" max-width="150" contain :src="appLogo"></v-img>
          </router-link>
        </v-card-title>
        <v-row justify="center" v-if="loading" class="mb-6">
          <v-col cols="auto">
            <v-progress-circular color="primary" indeterminate size="150"></v-progress-circular>
          </v-col>
        </v-row>
        <template v-else>
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">
              {{ $t('page.personify_accepted.title') }}
            </p>
            <p class="mb-2">{{ $t('page.personify_accepted.description') }}</p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-btn v-if="$store.state.user.id" block class="primary" @click="$router.push('/profile')">
              {{ $t('page.personify_accepted.to_profile') }}
            </v-btn>
            <v-btn v-else block class="primary" @click="$router.push('login')">
              {{ $t('page.personify_accepted.to_login') }}
            </v-btn>
          </v-card-text>
        </template>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  name: 'Registration',
  beforeMount() {
    this.token = new URLSearchParams(window.location.search).get('token')
    this.performer = new URLSearchParams(window.location.search).get('performer')
    this.target = new URLSearchParams(window.location.search).get('target')
    if (this.token && this.performer && this.target) {
      this.$http
        .post('accept_personification_request', {
          accept_token: this.token,
          performer: this.performer,
          target: this.target,
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
  data: () => ({
    loading: true,
    token: null,
    performer: null,
    target: null,

    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,
    arrowLeftIcon: mdiChevronLeft,
  }),
  methods: {
    createPasswordOnClick: function () {
      this.loading = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

